import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum SymbolType {
  anthem = 'anthem',
  map = 'map',
  coat = 'coat',
  flag = 'flag',
  valute = 'valute',
  nature = 'nature',
  dances = 'dances',
  food = 'food',
  stuff = 'stuff',
  cars = 'cars',
}

export enum SymbolNames {
  anthem = 'Државне химне',
  map = 'Државне мапе',
  coat = 'Државни грбови',
  flag = 'Државне заставе',
  valute = 'Новац',
  nature = 'Биљке и животиње',
  dances = 'Народне игре',
  food = 'Национална кухиња',
  stuff = 'Ствари и предмети',
  cars = 'Аутомобили',
}

export const symbolTypes = [
  { 'id': SymbolType.anthem, 'name': SymbolNames.anthem },
  { 'id': SymbolType.map, 'name': SymbolNames.map },
  { 'id': SymbolType.coat, 'name': SymbolNames.coat },
  { 'id': SymbolType.flag, 'name': SymbolNames.flag },
  { 'id': SymbolType.valute, 'name': SymbolNames.valute },
  { 'id': SymbolType.nature, 'name': SymbolNames.nature },
  { 'id': SymbolType.dances, 'name': SymbolNames.dances },
  { 'id': SymbolType.food, 'name': SymbolNames.food },
  { 'id': SymbolType.stuff, 'name': SymbolNames.stuff },
  { 'id': SymbolType.cars, 'name': SymbolNames.cars },
];
export interface Symbol {
  id?: string | null;
  name: string | null;
  slug: string | null;
  imagePath?: string | null;
  author: string | null;
  authorSlug: string | null;
  author2: string | null;
  authorSlug2: string | null;
  date: string | null;
  dateOrder: string | null;
  description: string | null;
  created: number | null;
  updated: number | null;
  type: SymbolType | null;
}

@Injectable({ providedIn: 'root' })
export class SymbolsService extends CrudService<Symbol> {
  override collectionName = 'symbols';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}